<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width picto-dowload">
      <div class="picto-block">
        <div class="picto-block__img" v-for="(img, i) in images" :key="i">
          <img :src="require(`../../../assets/img/picto-page/${img}`)" alt="">
        </div>
      </div>
      <!--      <PictoSlider v-bind:slides="slides"/>-->
      <div class="page__download-block ">
        <a :href="'../dowload-file/Icons/GDP-Icons.zip'"
           @click.prevent="downloadItemZip('../dowload-file/Icons/GDP-Icons.zip')"
           class="main-btn_download">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="16" stroke="white" stroke-linecap="round" stroke-dasharray="0.1 4"/>
            <path
                d="M23.6464 26.3536C23.8417 26.5488 24.1583 26.5488 24.3536 26.3536L27.5355 23.1716C27.7308 22.9763 27.7308 22.6597 27.5355 22.4645C27.3403 22.2692 27.0237 22.2692 26.8284 22.4645L24 25.2929L21.1716 22.4645C20.9763 22.2692 20.6597 22.2692 20.4645 22.4645C20.2692 22.6597 20.2692 22.9763 20.4645 23.1716L23.6464 26.3536ZM24.5 18L24.5 17.5L23.5 17.5L23.5 18L24.5 18ZM24.5 26L24.5 18L23.5 18L23.5 26L24.5 26Z"
                fill="white"/>
            <path d="M31 27V29C31 29.5523 30.5523 30 30 30H18C17.4477 30 17 29.5523 17 29V27" stroke="white"
                  stroke-linecap="square"/>
          </svg>
          Скачать пиктограммы
        </a>
      </div>
    </div>


    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/identity/typography">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Типографика
      </router-link>
      <router-link class="next-page__link" to="/identity/style">
        Фирменный стиль
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/identity/style">
        Фирменный стиль
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">

      </router-link>
    </div>
  </div>
</template>
<script>

import Axios from "axios";

export default {
  components: {},
  data() {
    return {
      images: [
        'Steor-Icons-18.svg',
        'Steor-Icons-07.svg',
        'Steor-Icons-14.svg',
        'Steor-Icons-22.svg',
        'Steor-Icons-10.svg',
        'Steor-Icons-03.svg',
        'Steor-Icons-17.svg',
        'Steor-Icons-06.svg',
        'Steor-Icons-13.svg',
        'Steor-Icons-21.svg',
        'Steor-Icons-09.svg',
        'Steor-Icons-02.svg',
        'Steor-Icons-16.svg',
        'Steor-Icons-19.svg',
        'Steor-Icons-12.svg',
        'Steor-Icons-20.svg',
        'Steor-Icons-08.svg',
        'Steor-Icons-01.svg',
        'Steor-Icons-15.svg',
        'Steor-Icons-04.svg',
        'Steor-Icons-11.svg',
      ]
    }
  },
  methods: {
    downloadItemZip(url) {
      Axios.get(url, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'icons'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/text-style";
@import "src/assets/style/page-block";
@import "src/assets/style/buttons";

.page {
  margin-bottom: 192px;

  &__full-width {
    max-width: 832px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.picto-dowload {

  .page__download-block {
    margin-top: 64px;
  }

  .picto-block {
    display: flex;
    flex-wrap: wrap;

    .picto-block__img {
      width: 112px;
      height: 112px;

      &:not(:nth-child(6n)) {
        margin-right: 32px;
      }

      &:not(:last-child) {
        margin-bottom: 32px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

}

.page__download-block {
  margin-left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.07em;
  text-transform: uppercase;

  svg {
    margin-right: 8px;
  }
}

.page__download-block {
  margin-left: 0;
}

.main-btn_download {
  letter-spacing: 0.85px;
  font-weight: 700;
  font-family: 'formular', serif;
  padding: 8px 29px 8px 8px;
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 204px;
  }
}

@media screen and (max-width: 1246px) {
  .page__download-block {
    display: none;
  }
}

@media screen and (max-width: 1087px) {
  .pages-wrap {
    .container {
      .page {
        .page__full-width {
          .picto-block {
            .picto-block__img {
              &:nth-child(6n) {
                margin-right: 32px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 481px) {
  .pages-wrap {
    .container {
      .page {
        .page__full-width {
          .picto-block {
            .picto-block__img {
              margin-right: 3px;
              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
